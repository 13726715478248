@import "./variables";

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    min-height: 100%;

    font-size: 24px;
    font-family: $fontOpenSans;
    color: $text-color;

    // background-attachment: fixed;
    // background-size: cover;
}

main,
aside,
dl,
dd {
    display: block;
}

#root {
    margin: 0;
    position: relative;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input[type="text"] {
    font-family: $fontOpenSans;
    font-weight: bold;
    font-size: 22px;
    color: $input-text-color;
}

h1 {
    font-size: 2em;
    font-family: $fontOswald;
    font-weight: 400;
    text-align: center;
    color: $header-color;
    margin: 0 auto;
    padding-top: 2%;
    text-transform: uppercase;
}

h2 {
    font-family: $fontOswald;
    font-weight: bold;
    font-size: 1em;
    color: $header-color;
    text-align: center;
}

h3 {
    font-family: $fontOswald;
    font-weight: 400;
    font-size: 1em;
    color: $header-color;
    text-align: center;
    text-transform: uppercase;
}

p {
    font-size: 1em;
    line-height: 1.5em;
    font-family: $fontOpenSans;
    max-width: 100%;
}

form {
    margin: 0;
}

body.with-cookies {
    padding-bottom: 300px;
}

.button-image {
    display: inline-block;  
    width: 396px;
    height: 64px;  
    border: none;
    outline: none;
    cursor: pointer;
    background-size: contain;
    &.button-adventure {
        width: 396px;
        background: url('./assets/buttons/button_adventure.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_adventure-hover.png');
        }
    }
    &.button-next {
        width: 229px;
        background: url('./assets/buttons/button_next.png') 50% 50% no-repeat;        
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_next-hover.png');
        }
    }
    &.button-room {        
        width: 211px;
        height: 58px;
        background: url('./assets/buttons/button_room.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_room-hover.png');
        }
    }
    &.button-complete {        
        width: 252px;
        height: 63px;
        background: url('./assets/buttons/button_complete.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_complete-hover.png');
        }
    }   
    &.button-download {        
        width: 252px;
        height: 63px;
        background: url('./assets/buttons/button_download.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_download-hover.png');
        }
    }
    &.button-end {        
        width: 211px;
        height: 51px;
        background: url('./assets/buttons/button_end.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_end-hover.png');
        }
    }
    &.button-manual {        
        width: 211px;
        height: 51px;
        background: url('./assets/buttons/button_manual.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_manual-hover.png');
        }
    }
    &.button-up {        
        width: 58px;
        height: 58px;
        background: url('./assets/buttons/button_up.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_up-hover.png');
        }
    }
    &.button-down {        
        width: 58px;
        height: 58px;
        background: url('./assets/buttons/button_down.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_down-hover.png');
        }
    }
    &.button-left {        
        width: 58px;
        height: 58px;
        background: url('./assets/buttons/button_left.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_left-hover.png');
        }
    }
    &.button-right {        
        width: 58px;
        height: 58px;
        background: url('./assets/buttons/button_right.png') 50% 50% no-repeat;
        background-size: contain;
        &:hover {
            background-image: url('./assets/buttons/button_right-hover.png');
        }
    }
        
    span {
        display: none;
    }
}

.button {
    $buttonHeight: 2em;

    text-decoration: none;
    font-family: $fontOpenSans;
    font-weight: bold;
    font-size: 30px;
    padding: 0;
    display: inline-block;
    position: relative;
    line-height: $buttonHeight;
    padding: 0 $buttonHeight/2;

    // margin-left: 1em;
    // margin-right: 1em;

    border: none;
    cursor: pointer;

    border-radius: 1em;

    &:focus,
    &:hover {
    }
}

.button-secondary {
    @extend .button;
    $border-size: 2px;

    background: none;
    position: relative;

    &:focus,
    &:hover {
    }
}

.button-big {
    display: inline-block;
    font-size: 1.7em;
    line-height: 2.7em;
    background: url('./assets/backgrounds/button.png') 50% 50% no-repeat;
    background-size: contain;
    font-family: Teko;
    font-weight: bold;
    color: $input-text-color;
    text-transform: uppercase;
    letter-spacing: 0px;
    max-width: 396px;
    width: 100%;
    text-align: center;
    border: none;
    cursor: pointer;

    &:hover {
        color: $input-text-color;
        background-image: url('./assets/backgrounds/button-3.png');
    }

    &:before {
        content: '\2022';
        display: inline-block;
        padding: 0 .2em;
    };

    &:after {
        content: '\2022';
        display: inline-block;
        padding: 0 .2em;
    }
}

a {
    color: $link-color;
    text-decoration: none;

    &:hover {
        color: $link-hover-color;
    }
}

/* ========= */

legend {
    padding: 0;
    display: table;
}
fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
}

.ending-text {
    font-size: 0.8em;
    img {
        max-height: 18px;
        margin: 0px !important;
    }
}

// checkbox
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 39px;
        width: 43px;
        background-image: url("./assets/checkbox/checkbox.png");
        background-repeat: no-repeat;
        background-size: cover;
        &.checkmark2 {
            background-image: url("./assets/checkbox/checkbox2.png");
        }
    }
    &:hover input ~ .checkmark 
    {
        background-image: url("./assets/checkbox/checkbox-hover.png");
    }
    input:checked ~ .checkmark {
        background-image: url("./assets/checkbox/checkbox-checked.png");
        &.checkmark2 {
            background-image: url("./assets/checkbox/checkbox2-checked.png");
        }
    } 
    input:checked ~ .checkmark:after {
        display: block;
    }
    .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}