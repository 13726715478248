$color1: #575757;
$color2: #99B0BC;
$color3: #57A9E6;
$color4: #ff2300;
$color5: #EEEEEE;
$color6: #000000;
$color7: #ffffff;
$color8: #B2ACD1;


$backgroud-color: $color7;
$background-footer-color: $color7;
$header-color: $color6;
$primary-color: $color2;
$secoundary-color: $color2;
$correct-color: $color3;
$error-color: $color4;
$input-placeholder-color: $color5;
$input-text-color: $color6;
$input-border-color: $color6;
$link-color: $color2;
$link-hover-color: $color3;

$text-color: $color6;
$text-alt-color: $color8;

$fontOpenSans: 'Open Sans', sans-serif;
$fontOswald: 'Oswald', sans-serif;
